<template>
  <b-container>
    <!-- <b-row no-gutters> -->
    <header id="nav" class="blog-header py-1 px-0">
      <b-navbar class="navbar-light bg-white px-0" toggleable="lg">
        <b-navbar-brand class="me-2 d-lg-block d-none" to="/"  ><img src="@/assets/images/logo_header.png" width="130"/></b-navbar-brand>

        <div class="justify-content-between d-lg-none d-flex w-custom-100 ">
          <b-navbar-brand class="me-2" to="/"  ><img src="@/assets/images/logo_header.png" width="130"/></b-navbar-brand>
          <b-navbar-toggle target="navbar-toggle-collapse">
            <template #default="{ expanded }">
              <b-icon
                  font-scale="1.5"
                  v-if="expanded"
                  icon="list-nested"
              ></b-icon>
              <b-icon font-scale="1.5" v-else icon="list"></b-icon>
            </template>
          </b-navbar-toggle>
        </div>


        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="navbar-nav lg:align-items-center pe-0 flex-grow-1">
            <b-nav-item>
              <i18n/>
            </b-nav-item>
            <b-nav-item>
              <b-link class="nav-link text-small" to="/">{{ $t('Home') }}</b-link>
            </b-nav-item>
            <b-nav-item>
              <a class="nav-link text-small" @click="openBlog()" href="https://mindology.io/fa"
              >{{ $t('Blog') }}</a
              >
            </b-nav-item>
            <b-nav-item>
              <b-link class="nav-link text-small" to="/about-us"
              >{{ $t('About') }}
              </b-link
              >
            </b-nav-item>

            <b-nav-item>
              <b-link class="nav-link text-small" to="/contact-us"
              >{{ $t('Contact') }}
              </b-link
              >
            </b-nav-item>

            <b-nav-item class="ms-lg-auto me-lg-0">
              <v-form form-class="p-0 ms-auto me-0" @submit="onSubmit">
                <v-text-field
                    :label="$t('Search...')"
                    dense
                    single-line
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-at"
                    v-model="search"
                ></v-text-field>
              </v-form>
            </b-nav-item>
            <b-nav-item>
              <v-btn color="primary" class="text-center text-white" @click="goToExternalUrl">
                {{ $t('Login') }}
              </v-btn>
            </b-nav-item>

            <!--login and logout code-->
            <!--            <b-nav-item v-if="!SignIn" class="d-none">-->
            <!--              <v-btn-->
            <!--                class="py-2 px-5 text-white rounded-pill text-small"-->
            <!--                to="/auth"-->
            <!--                color="primary"-->
            <!--                >{{$t('Login')}}-->
            <!--              </v-btn>-->
            <!--            </b-nav-item>-->

            <!--            <b-nav-item v-else class="d-none">-->
            <!--              <v-btn-->
            <!--                class="py-2 px-5 text-white rounded-pill f12"-->
            <!--                color="red darken-2"-->
            <!--                @click="LogOut()"-->
            <!--              >-->
            <!--                {{$t('Log out')}}-->
            <!--              </v-btn>-->

            <!--              <v-btn-->
            <!--                v-if="this.clientType == 'DOCTOR'"-->
            <!--                class="py-2 px-5 text-white rounded-pill ms-2 f12"              -->
            <!--                color="green darken-2"-->
            <!--                to="/doctor/home"-->
            <!--              >-->
            <!--                {{$t('WebApp')}}-->
            <!--              </v-btn>-->

            <!--              <v-btn-->
            <!--                v-else-->
            <!--                class="py-2 px-5 text-white rounded-pill ms-2 f12"              -->
            <!--                color="green darken-2"-->
            <!--                to="/user/home"-->
            <!--              >-->
            <!--                {{$t('WebApp')}}-->
            <!--              </v-btn>-->
            <!--              -->
            <!--            </b-nav-item>-->

          </b-navbar-nav>
        </b-collapse>

      </b-navbar>
    </header>
    <!-- </b-row> -->
  </b-container>
</template>

<script>

import I18n from "@/components/I18n.vue"

export default {
  name: "TheHeader",
  props: {
    msg: String,
  },
  components: {
    I18n,
  },
  data() {
    return {
      search: "",
      SignIn: false,
    };
  },
  methods: {
    goToExternalUrl() {
      const url =process.env.VUE_APP_EXTERNAL_URL;
      window.open(url, '_blank');
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.search) {
        this.$router.push(`/${this.search}`);
        this.search = "";
        this.disabled = "";
      } else {
        this.wrong = "";
      }
    },
    LogOut() {
      this.$store.dispatch("logout");
      this.$router.go();
    },
    openBlog() {
      window.open("https://mindology.io/fa")
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (min-width: 992px) {
  .w-custom-100{
    width:130px;
  }
}
@media screen and (max-width: 991.999px) {
  .w-custom-100{
    width:100%;
  }
}

</style>
