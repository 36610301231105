<template>
  <div>
    <b-container v-if="!isMobile()">
      <b-row>
        <b-col cols="12">
          <div
            class="d-flex align-items-center secondary curve p-2 mt-2 text-right"
          >
            <img
              src="@/assets/images/emergency-call.png"
              alt="emergency-call"
              class="m-2"
            />
            <span class="text-white me-auto"
              >در مواقع اورژانسی و فکر به خودکشی با اورژانس اجتماعی تماس
              بگیرید.</span
            >
            <span class="bg-white p-2 curve ms-2">ایران: 123</span>
            <span class="bg-white p-2 curve ms-2">افغانستان: 119</span>
            <span class="bg-white p-2 curve ms-2 me-3"
              >آمریکا و کانادا: 911</span
            >
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else-if="isMobile()">
      <b-row>
        <b-col cols="12">
          <div
            class="d-flex flex-column align-items-center secondary curve p-2 mt-2 text-right"
          >
            <img
              src="@/assets/images/emergency-call.png"
              alt="emergency-call"
              class="m-2"
            />
            <span class="text-white mx-3 text-center"
              >در مواقع اورژانسی و فکر به خودکشی با اورژانس اجتماعی تماس
              بگیرید.</span
            >
            <span class="bg-white p-2 curve mt-2">ایران: 123</span>
            <span class="bg-white p-2 curve mt-2">افغانستان: 119</span>
            <span class="bg-white p-2 curve mt-2">آمریکا و کانادا: 911</span>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- <b-row no-gutters> -->
      <v-footer class="secondary white--text my-4 curve p-4">
        <b-container fluid>
          <b-row justify="center" align-h="between">
            <b-col cols="12" md="8" class="order-xs-2">
              <h6 class="">مایندولوژی را بهتر بشناسید</h6>
              <p class="small ps-md-2 mt-4 font-weight-light text-justify">
                شرکت مبتکران داده لیان با نام تجاری مایندولوژی ارائه کننده خدمات
                مشاوره آنلاین روانشناسی است که هدف اصلی آن تجربه درمان بهتر و
                سریعتر برای مراجعین و ارائه ابزارهای نوین درمانی در بستر
                تکنولوژی برای مشاورین می باشد. مشاوره انلاین روانشناسی در یک
                محیط کاملا خصوصی و امن بین مشاورین و مراجعین برگزار می گردد. شما
                میتوانید بدون محدودیت زمانی و مکانی در سریع ترین زمان ممکن به
                متخصصین روانشناسی دسترسی داشته باشید و از خدمات مشاوره ای و
                درمانی استفاده نمائید.
              </p>

<!--              <div class="d-flex flex-sm-row flex-column align-items-center">-->
<!--                <span class="mb-1">ورود به وب اپ</span>-->
<!--                <b-button-->
<!--                  class="p-0 w-auto mx-1 mb-1"-->
<!--                  variant="white"-->
<!--                  @click="downloadFromGooglePlay()"-->
<!--                >-->
<!--                  <v-img-->
<!--                    :src="require('@/assets/images/badge/google-play-badge-fa.png')"-->
<!--                    max-width="140"-->
<!--                  />-->
<!--                </b-button>-->

<!--                <b-button-->
<!--              class="p-0 w-auto mx-1 mb-1"-->
<!--              variant="white"-->
<!--              @click="downloadFromBazzar()"-->
<!--              >-->
<!--                <v-img :src="require('@/assets/images/badge/cafebazaar-fa.png')" max-width="140"/>-->
<!--              </b-button-->
<!--            >-->

<!--                &lt;!&ndash; <b-button-->
<!--                  class="p-0 w-auto mb-1"-->
<!--                  variant="white"-->
<!--                  @click="downloadFromAnardooni()"-->
<!--                >-->
<!--                  <v-img-->
<!--                    :src="-->
<!--                      require('@/assets/images/badge/anardoni-badge-black-persian.png')-->
<!--                    "-->
<!--                    max-width="140"-->
<!--                  />-->
<!--                </b-button> &ndash;&gt;-->

<!--                <div-->
<!--                  @click="downloadPage()"-->
<!--                  class="mx-1 mb-1 pointer"-->
<!--                  style="width: 44px; height: 44px; border-radius: 4px"-->
<!--                >-->
<!--                  <img-->
<!--                    class="w-100 white"-->
<!--                    width="44"-->
<!--                    height="44"-->
<!--                    style="object-fit: cover; border-radius: 4px;"-->
<!--                    :src="require('@/assets/images/More.svg')"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
            </b-col>

            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center flex-column order-xs-1"
            >
              <!-- <b-link class="ms-auto my-auto text-center text-sm-left w-100" to="/homepage"> -->
              <b-link
                class="ms-auto my-auto text-center text-sm-left w-100"
                to="/"
              >
                <img
                  class="stroke w-50"
                  src="@/assets/images/logo_wp.png"
                  alt="مایندولوژی"
                />
              </b-link>

              <div class="h6 text-center text-sm-left w-100 mt-3">
                <!-- <b-link
                  ><b-icon class="mx-2" icon="linkedin" variant="white"></b-icon
                ></b-link> -->
                <!-- <b-link
                  ><b-icon class="mx-2" icon="facebook" variant="white"></b-icon
                ></b-link> -->
                <b-link
                  href="https://www.instagram.com/mindology.io"
                  target="_blank"
                  ><b-icon
                    class="mx-2"
                    icon="instagram"
                    variant="white"
                  ></b-icon
                ></b-link>
                <!-- <b-link
                  ><b-icon
                    class="mx-2"
                    icon="exclamation-circle-fill"
                    variant="white"
                  ></b-icon
                ></b-link> -->
                <b-link
                  ><b-icon class="mx-2" icon="twitter" variant="white"></b-icon
                ></b-link>
              </div>
              <b-link
                class="inline text-white small w-100 text-center text-sm-left"
                to="/policy"
                >قوانین و شرایط استفاده از خدمات مایندولوژی</b-link
              >
              <b-link
                class="inline text-white small w-100 text-center text-sm-left"
                to="/privacy"
                >حریم خصوصی</b-link
              >
            </b-col>
          </b-row>
          <hr class="border-light" />
          <p class="text-white small font-weight-light text-center m-0">
            طراحی و تولید با

            <img
              class="emoji mx-1"
              alt="heart"
              height="20"
              width="20"
              :src="require('@/assets/images/heart.png')"
            />
            توسط مایندولوژی
          </p>
          <p class="text-white small font-weight-light text-center m-0">
            © تمام حقوق برای mindology.io محفوظ است
          </p>
        </b-container>
      </v-footer>
      <!-- </b-row> -->
    </b-container>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      articles: [
        {
          id: 0,
          title: "چگونه می توانم برای زندگی خودم برنامه ریزی درست داشته باشم؟",
          createTime: "اسفند 1399، 12:40",
        },
        {
          id: 1,
          title: "از زندگی خود راضی نیستم",
          createTime: "بهمن 1399، 14:00",
        },
        {
          id: 2,
          title: "آیا می توانم زنده بمانم؟",
          createTime: "فروردین 1400، 19:45",
        },
      ],
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    downloadFromGooglePlay() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.mindology.app"
      );
    },
    downloadFromBazzar() {
      window.open("https://cafebazaar.ir/app/com.mindology.app");
    },
    downloadPage() {
      this.$router.push("/download")
    },
    downloadFromAnardooni() {
      window.open("https://anardoni.com/ios/app/3QFzGyNfU");
    },
  },
};
</script>
