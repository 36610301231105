<template>
  <v-menu rounded="b-xl" offset-y class="cursor-pointer">
    <template v-slot:activator="{ attrs, on }">
      <span
        class="cursor-pointer flex items-center i18n-locale mt-lg-2"
        v-bind="attrs"
        v-on="on"
      >
        <span class="ml-2">{{ getCurrentLocaleData.lang }}</span>
        <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
      </span>
    </template>
    <v-list class="w-48 i18n-dropdown vx-navbar-dropdown">
      <v-list-item @click="updateLocale('fa')"
        ><img
          class="h-4 w-5 me-1"
          src="@/assets/images/flags/fa.png"
          alt="fa"
        />
        &nbsp;فارسی</v-list-item
      >
      <v-list-item @click="updateLocale('en')"
        ><img
          class="h-4 w-5 me-1"
          src="@/assets/images/flags/en.png"
          alt="en"
        />
        &nbsp;English</v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    i18n_locale_img() {
      return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    },
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { flag: "us", lang: "English" };
      else if (locale == "fa") return { flag: "fa", lang: "فارسی" };
    },
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      if (locale == "en") this.changeRTL(false);
      else if (locale == "fa") this.changeRTL(true);
    },
    changeRTL(isRtl) {
      this.$vuetify.rtl = isRtl;
    },
  },
};
</script>
